#home_footer_container{
    float: left;
    width: 100%;
    height: 40px;
    text-align: center;
    margin-top: 40px;
}

#home_footer_container>img{
    width: 20px;
    height: 20px;
    margin-left: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
    float: left;
}

#home_footer_container>div{
    font-size: 14px;
    color: #cccccc;
}

#home_footer_container>a{
    line-height: 40px;
    height: 40px;
    font-size: 10px;
    color: #cccccc;
    text-decoration: none;
    float: left;
}
