#home_header_container{
    height: 40px;
}

#home_header_left{
    height: 100%;
    float: left;
    padding-right: 40px;
}

#home_header_left>img{
    height: 100%;
    float: left;
}

#home_header_left>span{
    height: 100%;
    line-height: 40px;
    float: left;
    margin-left: 10px;
}

.home_header_title{
    font-weight: bold;
    height: 100%;
    color: black;
    font-size: 30px;
}

#home_header_right{
    height: 100%;
    float: right;
    line-height: 40px;
}

