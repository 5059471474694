#download_logo{
    text-align: center;
    padding-top: 20px;
}

#download_text_container{
    text-align: center;
    padding-top: 40px;
}

.download__text{
    color: black;
}

.download_highlight_text{
    font-weight: bold;
    color: orange;
}

#download_indicator{
    width: 20px;
    right: 15px;
    position: absolute;
}
