/* 全局基础样式 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f4;
  color: #333;
}

/* 顶部公司信息样式 */
.App-header {
  background-color: #004080;
  color: white;
  padding: 2rem;
  text-align: center;
}

.company-info h1 {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}

.company-info p {
  font-size: 1.2rem;
}

/* 中间展示APP产品样式 */
.product-showcase {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 2rem;
  margin: 0 auto;
  max-width: 1200px;
}

.app-product {
  background-color: white;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.app-product:hover {
  transform: translateY(-10px);
}

.app-product img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.app-product h2 {
  font-size: 1.5rem;
  margin: 1rem 0;
  color: #004080;
}

.app-product p {
  font-size: 1rem;
  color: #666;
}

/* 底部公司信息样式 */
.App-footer {
  background-color: #333;
  color: white;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;
}

.contact-info, .record-info {
  margin: 1rem;
}

.App-footer p {
  margin: 0.5rem 0;
}

.App-footer h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.App-footer a {
  color: white;  /* 将超链接文字颜色设为白色 */
  text-decoration: none;  /* 移除默认的下划线 */
}

.App-footer a:hover {
  text-decoration: underline;  /* 鼠标悬停时显示下划线 */
}