#home_contact_container{
    float: left;
    width: 100%;
    margin-top: 80px;
    background-color: #eeeeee;
}

#home_contact_container>span{
    height: 100%;
    line-height: 80px;
    float: left;
    margin-left: 20px;
    color: black;
    font-size: 14px;
}

#home_contact_container>a{
    height: 100%;
    line-height: 80px;
    float: left;
    margin-left: 20px;
    color: black;
    font-size: 14px;
}

